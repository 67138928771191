import {DFTLNGCODE, URL as NEWURL} from '../constants';
import {VENDORID} from '../constants';

export default function base64Upload(folderName,imageName,base64) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: JSON.stringify({
            "serviceId": 3003,
            "vendorId": VENDORID,
            "lang": localStorage.getItem('rcml-lang')??DFTLNGCODE,
            "fileBase64": base64,
            "fileFolder": folderName,
            "fileName": imageName,

        })
    };

    const fetchData = async () => {
        try {
            const response = await fetch(NEWURL, requestOptions);
            const json = await response.json();
            console.log(json)
            return json;
        } catch (error) {
            console.log("error", error);
        }
    };

    return fetchData();
}


export const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };
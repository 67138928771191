import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { PAYMENTSERVER, PAYMENTSYSTEM } from "../../constants";
// import "./Stripe.css";

const stripePromise = loadStripe("pk_test_51PH13VRsZipBz0hYhh485de11vlC4U0EMjE3ro5DVXNj6DMddPp5Ac7q4ftFMPfX9ApAWl9dg50nBmuxzIiuxfmc00pzwk346c");

export default function StripeTest({amount}) {
  const [clientSecret, setClientSecret] = useState("");
  console.log(amount);
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(PAYMENTSERVER+"/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
     },
      body: JSON.stringify({ amount}),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {(clientSecret&&PAYMENTSYSTEM) && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}